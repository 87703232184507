
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import ListaPortalAdministradoras from './pages/listaPortalAdministradoras'
import CadastroPortalAdministradoras from './pages/CadastroPortalAdministradoras'
import Redirect from './pages/redirect'

export default function App() {

  return (
    <BrowserRouter>
      <Switch>
        {/* <Route path='/:id?' exact component={ListaPortalAdministradoras} /> */}
        <Route path='/CadastroPortalAdministradoras' exact component={CadastroPortalAdministradoras} />
        <Route path="/ListaPortalAdministradoras" exact component={ListaPortalAdministradoras} />
        <Route path="/redirect/:id" exact component={Redirect} />
      </Switch>
    </BrowserRouter>
  )
}