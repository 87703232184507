import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

const RedirectComponent: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  useEffect(() => {
    console.log('ID RECEBIDO: ', id);
    if (id) {
      // Passa o id pelo estado
      history.replace({
        pathname: '/ListaPortalAdministradoras',
        state: { id }, // Passando o id no estado
      });
    }
  }, [id, history]);

  return null;
};

export default RedirectComponent;