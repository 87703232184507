import { SetStateAction, useEffect, useState } from 'react';
import { Button, Table, Input, Tooltip, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import * as S from './styles';
import { toast } from 'react-toastify';
import api from '../../service/api';
import { useHistory, useLocation } from 'react-router-dom';

interface DataItem {
    empresa: string;
    administradora: string;
    cnpj: string;
    campo1: string;
    campo2: string;
    campo3: string;
    link: string;
    id: string;
}

export default function TablePortalAdministradoras({ currentUser }: any) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<DataItem[]>([]);
    const [filteredData, setFilteredData] = useState<DataItem[]>([]);
    const [nome, setParams] = useState("");
    const [selectedCompany, setSelectedCompany] = useState<string>('');
    const [selectedAdmin, setSelectedAdmin] = useState<string>('');
    const [adminOptions, setAdminOptions] = useState<string[]>([]);
    const [companyOptions, setCompanyOptions] = useState<string[]>([]);
    const { Search } = Input;
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [copyStatus, setCopyStatus] = useState<string | number | null>(null);

    const handleCopy = (text: string, key: string | number) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopyStatus(key);
            setTimeout(() => setCopyStatus(null), 2000);
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const nomeDoGrupo = queryParams.get('groupName');

            try {
                const response = await api.get(`/api/LoginPortal/portalAdministradoras?groupName=${encodeURIComponent(currentUser?.nomeDoGrupo)}`);
                if (response.data && response.data.result) {
                    const result: DataItem[] = response.data.result;
                    setData(result);
                    setFilteredData(result);

                    // Define todas as opções disponíveis inicialmente e ordena alfabeticamente
                    const allCompanies = Array.from(new Set(result.map((item) => item.empresa))).sort();
                    const allAdmins = Array.from(new Set(result.map((item) => item.administradora))).sort();

                    setCompanyOptions(allCompanies);
                    setAdminOptions(allAdmins);
                } else {
                    setData([]);
                    setFilteredData([]);
                    setCompanyOptions([]);
                    setAdminOptions([]);
                    toast.info('Nenhum dado encontrado.');
                }
            } catch (error: any) {
                toast.error('Erro ao buscar dados: ' + error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [currentUser]);

    useEffect(() => {
        let filtered = data;

        if (selectedCompany) {
            filtered = filtered.filter((item) => item.empresa === selectedCompany);
        }

        if (selectedAdmin) {
            filtered = filtered.filter((item) => item.administradora === selectedAdmin);
        }

        if (nome) {
            filtered = filtered.filter((item) =>
                item?.empresa.toLowerCase().includes(nome.toLowerCase())
            );
        }

        setFilteredData(filtered);
    }, [selectedCompany, selectedAdmin, nome, data]);

    useEffect(() => {
        if (selectedCompany) {
            // Filtra as administradoras com base na empresa selecionada
            const admins = data
                .filter((item) => item.empresa === selectedCompany)
                .map((item) => item.administradora);
            setAdminOptions(Array.from(new Set(admins)).sort()); // Remove duplicatas e ordena alfabeticamente
        } else {
            // Se nenhuma empresa estiver selecionada, exibe todas as administradoras disponíveis
            setAdminOptions(Array.from(new Set(data.map((item) => item.administradora))).sort());
        }
    }, [selectedCompany, data]);

    useEffect(() => {
        if (selectedAdmin) {
            // Filtra as empresas com base na administradora selecionada
            const companies = data
                .filter((item) => item.administradora === selectedAdmin)
                .map((item) => item.empresa);
            setCompanyOptions(Array.from(new Set(companies)).sort()); // Remove duplicatas e ordena alfabeticamente
        } else {
            // Se nenhuma administradora estiver selecionada, exibe todas as empresas disponíveis
            setCompanyOptions(Array.from(new Set(data.map((item) => item.empresa))).sort());
        }
    }, [selectedAdmin, data]);

    const formatCNPJ = (cnpj: string) => {
        if (!cnpj) return '';
        return cnpj
            .replace(/\D/g, '') // Remove tudo que não é número
            .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5'); // Formata
    };

    function OpenLink(link: string) {
        const fullLink = link.startsWith('http://') || link.startsWith('https://')
            ? link
            : `http://${link}`;

        const win = window.open(fullLink, "_blank");
        if (win) {
            win.focus();
        } else {
            alert("Por favor, permita pop-ups para abrir o link.");
        }
    }

    const columns = [
        {
            title: 'EMPRESA',
            dataIndex: 'empresa',
            key: 'empresa',
            width: 150,
        },
        {
            title: 'CNPJ',
            dataIndex: 'cnpj',
            key: 'cnpj',
            width: 120,
            render: (cnpj: string) => formatCNPJ(cnpj),
        },
        {
            title: 'ADMINISTRADORA',
            dataIndex: 'administradora',
            key: 'administradora',
            width: 150,
        },
        {
            title: 'CAMPO 1',
            dataIndex: 'campo1',
            key: 'campo1',
            width: 150,
            render: (item: string, record: DataItem) => (
                <>
                    {item}
            
                    {item && (
                        <Tooltip title={copyStatus === record.id ? "Copiado!" : "Copiar"}>
                            <Button
                                type="link"
                                onClick={() => handleCopy(item, record.id)}
                                style={{ paddingLeft: '5px' }}
                                icon={<CopyOutlined style={{ color: '#B0B0B0' }} />} 
                            />
                        </Tooltip>
                    )}
                </>
            ),
        },
        {
            title: 'CAMPO 2',
            dataIndex: 'campo2',
            key: 'campo2',
            width: 150,
            render: (item: string, record: DataItem) => (
                <>
                    {item}
            
                    {item && (
                        <Tooltip title={copyStatus === record.id ? "Copiado!" : "Copiar"}>
                            <Button
                                type="link"
                                onClick={() => handleCopy(item, record.id)}
                                style={{ paddingLeft: '5px' }}
                                icon={<CopyOutlined style={{ color: '#B0B0B0' }} />} 
                            />
                        </Tooltip>
                    )}
                </>
            ),
        },
        {
            title: 'CAMPO 3',
            dataIndex: 'campo3',
            key: 'campo3',
            width: 150,
            render: (item: string, record: DataItem) => (
                <>
                    {item}
            
                    {item && (
                        <Tooltip title={copyStatus === record.id ? "Copiado!" : "Copiar"}>
                            <Button
                                type="link"
                                onClick={() => handleCopy(item, record.id)}
                                style={{ paddingLeft: '5px' }}
                                icon={<CopyOutlined style={{ color: '#B0B0B0' }} />} 
                            />
                        </Tooltip>
                    )}
                </>
            ),
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            render: (id: string, record: DataItem) => (
                <>
                    <Button
                        onClick={() => OpenLink(record.link)}
                        type="primary"
                        style={{ margin: "2px", borderRadius: "4px" }}
                    >
                        Acessar portal
                    </Button>
                    <Button
                        onClick={() => {
                            console.log('Registro a ser editado:', record);
                            // Redireciona para a página de edição e passa os dados do registro
                            history.push({
                                pathname: '/CadastroPortalAdministradoras', // Caminho da página de edição
                                state: { record }, // Passa os dados do registro como estado
                            });
                        }}
                        type="primary"
                        style={{
                            margin: "2px",
                            borderRadius: "4px",
                            backgroundColor: 'orange',
                            borderColor: 'orange',
                            color: 'white'
                        }}
                    >
                        Editar
                    </Button>
                </>
            )
        },
    ];

    function onSearchEnter(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        setParams(e.target.value); // Atualiza o estado do parâmetro de busca
    }

    const onSearch = (value: string) => { setParams(value) };

    const handleRedirect = () => {
        history.push('/CadastroPortalAdministradoras');
    };

    return (
        <>
            <S.DivTable>
                <S.Tools>
                    <S.SearchContainer>
                        <S.MenuContainer>
                            <select
                                value={selectedCompany}
                                onChange={(e) => {
                                    setSelectedCompany(e.target.value);
                                }}
                            >
                                <option value="">Selecionar Empresa</option>
                                {companyOptions.map((company: string) => (
                                    <option key={company} value={company}>
                                        {company}
                                    </option>
                                ))}
                            </select>
                        </S.MenuContainer>
                        <S.MenuContainer style={{ marginLeft: '10px' }}>
                            <select
                                value={selectedAdmin}
                                onChange={(e) => setSelectedAdmin(e.target.value)}
                            >
                                <option value="">Selecionar Administradora</option>
                                {adminOptions.map((admin: string, index: number) => (
                                    <option key={index} value={admin}>
                                        {admin}
                                    </option>
                                ))}
                            </select>
                        </S.MenuContainer>
                        <Button type="primary" onClick={handleRedirect} style={{ float: 'right', marginLeft: '10px', marginTop: `6px` }}>
                            Novo Cadastro
                        </Button>
                    </S.SearchContainer>
                </S.Tools>
                <Table
                    pagination={{
                        pageSize: 20,          // Define o tamanho da página
                        showSizeChanger: false, // Remove o combobox para seleção do tamanho da página
                        showQuickJumper: false, // Opcional: Remove o seletor de páginas rápidas
                    }}
                    loading={isLoading}
                    columns={columns}
                    dataSource={filteredData}
                />
            </S.DivTable>
        </>
    );
}
